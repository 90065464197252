<!--  -->
<template>
  <div class="mui-content" v-if="product">
    <van-swipe :autoplay="bannerTime" @change="onChange">
      <van-swipe-item v-for="(image, index) in product.bannerImgList" :key="index">
        <img v-lazy="image" />
      </van-swipe-item>
      <div class="custom-indicator" slot="indicator" v-if="product.bannerImgList">
        {{ current + 1 }}/{{product.bannerImgList.length}}
      </div>
    </van-swipe>
    <!--商品信息-->
    <div class="product-body">
      <div class="price-box">
        <span>￥</span>
        <span class="discount-price">{{ product.goodsPrice }}</span>
        <van-tag type="primary" round color="#ffb5bb">加购价</van-tag>
        <span class="original-price">官方价：￥{{ product.originalPrice }}</span>
      </div>
      <div class="pro-name mui-ellipsis">{{product.goodsName}}</div>
      <div v-if="product.deliveryTimeStr" class="delivery-time mui-ellipsis2">({{ product.deliveryTimeStr }})</div>
      <p class="sold-num">已订购{{product.buyedCountStr}}件</p>
    </div>
    <!--商品规格-->
    <div class="mi-norm">
      <div class="mi-item">
        <label>规格</label>
        <div class="mi-item-body">
          <p>{{product.specifications}}</p>
        </div>
      </div>
      <div class="mi-item">
        <label>功效</label>
        <div class="mi-item-body">
          <p>{{product.actEffect}}</p>
        </div>
      </div>
    </div>
    <van-tabs v-model="subTabId" class="pro-tabs" :border="false">
      <van-tab title="商品详情">
        <div class="sub-title">
          <span>商品详情</span>
        </div>
        <div class="pro-detail" v-html="product.goodsDetails"></div>
        <div class="ivv-notice" v-for="(it,index) in purchaseNotice" :key="index">
          <img :src="it.url" />
        </div>
      </van-tab>
      <van-tab title="检验证明" v-if="product.inCertificate">
        <div class="sub-title">
          <span>检验证明</span>
        </div>
        <div class="pro-detail" v-html="product.inCertificate"></div>
      </van-tab>
      <van-tab title="测评报告" v-if="product.evaReport">
        <div class="sub-title">
          <span>测评报告</span>
        </div>
        <div class="pro-detail" v-html="product.evaReport"></div>
      </van-tab>
      <van-tab title="备案查询" v-if="product.recordSel">
        <div class="sub-title">
          <span>备案查询</span>
        </div>
        <div class="pro-detail" v-html="product.recordSel"></div>
      </van-tab>
    </van-tabs>
  </div>

</template>

<script>
export default {
  name: 'ProductDetail',
  props: {
    product: {
      type: Object,
      default() {
        return null
      }
    },
    noticeType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      proId: 0,
      brandId: 3,
      preViewList: [],
      isPreViewSHow: false,
      initCount: 0,
      brandName: '',
      bannerTime: 5000,
      subTabId: 0,
      current: 0,//轮播索引
      imgIndex: 0,//图片预览页码
      isShowShareLayer: false,

      purchaseNotice: []//购买告知书
    }
  },
  created: function () {
    if (this.noticeType > 0) {
      this.getPurchaseNotice();
    }

  },
  methods: {
    back: function () {
      window.history.go(-1);
    },
    //打开图片预览
    openImgPreView: function (e, index) {
      if (index >= 0) {
        this.imgIndex = index;
        this.isPreViewSHow = true;
      }
    },
    //轮播
    onChange: function (index) {
      this.current = index;
      var list = this.$refs.videoList;
      if (list && list.length > 0) {
        for (var i = 0; i < list.length; i++) {
          list[i].pause();
        }
      }
    },
    //播放视频
    miPlay: function (item) {
      item.isPlaying = true;
      var list = this.$refs.videoList;
      if (list && list.length > 0) {
        for (var i = 0; i < list.length; i++) {
          var id = +(list[i].getAttribute("data-id"));
          if (item.id === id) {
            list[i].play();
          } else {
            list[i].pause();
          }
        }
      }
      this.bannerTime = 0;
    },
    //暂停视频
    miPause: function (item) {
      this.bannerTime = 3000;
      item.isPlaying = false;
    },
    jumpPage: function (url) {
      if (url) {
        this.addToOnline(this.count, function () {
          window.location.href = url;
        });
      }
    },
    //获取购买告知书
    getPurchaseNotice: function () {
      this.get('/Base/ConfigAddress/GetWebInformationList?', {
        type: this.noticeType
      }, 2).then(res => {
        if (res.code === 1) {
          this.purchaseNotice = res.response;
        }
      })
    }
  },
  computed: {}
}
</script>
<style lang='scss' scoped>
.mui-content {
  text-align: left;
  overflow-x: hidden;
  margin-bottom: 60px;
  background-color: #f8f8f8;

  .van-swipe {
    .van-swipe-item {
      font-size: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .custom-indicator {
      position: absolute;
      color: #fff;
      right: 15px;
      bottom: 15px;
      padding: 2px 15px;
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.4);
    }

    &.banner {
      height: 191px;
    }
  }

  .product-body {
    padding: 0 15px 15px;
    background-color: #fff;

    .price-box {
      color: #ffb5bb;
      padding: 5px 0;
      .discount-price {
        font-size: 30px;
      }
      .van-tag {
        padding: 2px 4px;
        font-size: 12px;
        display: inline-block;
        transform: scale(0.8);
      }
      .original-price {
        color: #999999;
        font-size: 10px;
        text-decoration: line-through;
      }
    }

    .pro-name {
      color: #666;
      padding: 10px 0;
      font-size: 16px;
    }

    .delivery-time {
      color: #ff98a0;
    }

    .sold-num {
      color: #b5b5b5;
    }
  }

  .mi-norm {
    margin-top: 10px;
    padding: 11px 15px;
    background-color: #fff;

    .mi-item {
      padding: 15px 0;
      overflow: hidden;
      position: relative;

      label {
        float: left;
        color: #999;
        width: 50px;
      }

      .mi-item-body {
        overflow: hidden;

        p {
          color: #666;
          font-size: 12px;
        }
      }

      &:after {
        content: '';
        clear: both;
        position: absolute;
        left: 50px;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: #f1f1f1;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;

        &:after {
          height: 0;
        }
      }
    }
  }

  .recommend {
    padding: 11px 0;
    margin-top: 10px;
    background-color: #fff;

    .sub-title {
      color: #666;
      font-size: 14px;
      padding-left: 15px;
      margin-bottom: 15px;
    }

    .van-swipe-item {
      overflow: hidden;
      padding: 0 15px;
      position: relative;
      box-sizing: border-box;

      .mi-media-object {
        width: 80px;
        height: 80px;
        float: left;
        margin-right: 10px;
      }

      .mi-media-body {
        .pro-name {
          color: #636363;
          font-size: 14px;
        }

        .price-box {
          padding-top: 10px;

          .vip {
            color: #ff98a0;
            font-size: 16px;
            font-weight: bold;
          }

          .vip-text {
            color: #fff;
            font-size: 10px;
            padding: 2px 4px;
            line-height: 10px;
            border-radius: 14px;
            display: inline-block;
            background-color: #ffb5bb;
          }

          del {
            color: #b5b5b5;
          }
        }

        .sold-num {
          color: #b5b5b5;
        }
      }

      &:after {
        content: '';
        clear: both;
        position: absolute;
        top: 15px;
        right: 15px;
        bottom: 15px;
        width: 1px;
        background-color: #f1f1f1;
      }

      &:last-child {
        &:after {
          width: 0;
        }
      }
    }
  }
  .van-cell-group {
    margin-top: 10px;
    padding: 11px 15px;
    img {
      max-width: 100%;
    }
  }
  .pro-tabs {
    border-top: 10px solid #f8f8f8;

    .van-tabs__line {
      display: none;
    }

    .van-tab {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 13px;
        right: 0;
        bottom: 13px;
        width: 1px;
        background-color: #f1f1f1;
      }

      &:last-child {
        &:after {
          width: 0;
        }
      }

      &.van-tab--active {
        color: #ff98a0;
      }
    }

    .van-tabs__content {
      .sub-title {
        padding: 15px 0;
        position: relative;
        text-align: center;
        background-color: #fff;

        span {
          color: #999;
          z-index: 10;
          padding: 0 15px;
          position: relative;
          background-color: #fff;
        }

        &:after {
          content: '';
          position: absolute;
          left: 15%;
          right: 15%;
          bottom: 23px;
          height: 1px;
          z-index: 1;
          background-color: #f1f1f1;
        }
      }

      .pro-detail {
        img {
          max-width: 100%;
        }
      }

      .certificate {
      }
    }
  }

  .van-image-preview {
    .van-swipe-item {
      img {
        height: auto;
        max-height: initial;
      }
    }
  }

  .mui-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .mi-share {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);

    .mi-poster {
      position: absolute;
      top: 20%;
      left: 10%;
      right: 10%;
      z-index: 10;
      font-size: 0;
      box-sizing: border-box;

      img {
        max-width: 100%;
      }

      .prompt {
        color: #666;
        font-size: 22px;
        margin-top: -20px;
        position: relative;
        text-align: center;
        padding: 20px 0 10px;
        background-color: #fff;
        border-radius: 0 0 30px 30px;

        &:before {
          content: '';
          position: absolute;
          top: 20px;
          left: 0;
          right: 0;
          height: 1px;
          background-color: #f5f5f5;
        }

        img {
          max-width: 15px;
        }

        span {
          font-size: 13px;

          &:last-child {
            color: #ff98a0;
          }
        }
      }
    }

    canvas {
      opacity: 0;
    }
  }
  /deep/.pro-detail {
    img {
      max-width: 100%;
    }
  }
  .ivv-notice {
    > img {
      width: 100%;
    }
  }
}

.iphoneX {
  .mui-content {
    .footer {
      .mui-bar-tab {
        padding-bottom: 34px;

        .car-text,
        .buy-box {
          bottom: 39px;
        }
      }

      .prompt {
        bottom: 34px;
      }
    }
  }
}
</style>