<!-- 显示特惠专区商品商品 -->
<template>
  <div class="container">
    <product-detail :product="product" :notice-type="42" />
    <div class="footer">
      <div class="shop-cart" @click="handleBack">
        返回列表
      </div>
      <div class="total">
        购物袋共计{{ totalCount }}件，总价：<span>￥</span><span class="amount">{{ totalAmount }}</span>
        <!-- <p>您还有<span>{{ addCount }}</span>次购买机会！</p> -->
      </div>
      <div class="cus-stepper">
        <van-stepper v-model="value" :min="0" :max="max" theme="round" button-size="22" disable-input @change="numberChange" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductDetail from "../../../components/Product/Detail.vue"
import { Subtr, accMul } from "../../../utils/handle"
export default {
  name: 'RetailProductDetail',
  components: { ProductDetail },
  data() {
    return {
      brandId: 3,
      goodId: '',
      product: null,
      totalCount: 0,
      totalAmount: 0,
      value: 0,
      addCount: 0,
      max: 9999
    }
  },
  created() {
    let { goodId, brandId } = this.$route.query;
    this.goodId = +goodId;
    this.brandId = +brandId;
    this.getProductById();
    this.getActivityInfo();
  },
  methods: {
    //根据商品id获取商品数据
    getProductById: function () {
      this.$toast.loading({ duration: 0, message: '加载中...', mask: true });
      this.get('/OrderGoods/RetailZone/GetRetailZoneGoodsDetails?v=1&goodsId=' + this.goodId, {},
        2).then(json => {
          this.$toast.clear();
          if (json.code === 1) {
            let { selloutImg, goodsId } = json.response;
            if (selloutImg) this.max = 0;
            this.product = json.response;
            this.getCartNumById(goodsId);
          }
        });
    },
    //获取限时特惠信息
    getActivityInfo() {
      this.get("/OrderGoods/PreferentialZone/GetPreferentialZone", {
        brandId: this.brandId
      }, 2).then(res => {
        if (res.code === 1) {
          let { count, endTimes } = res.response;
          this.addCount = count;
          let time = endTimes - +new Date();
          if (time <= 0) {
            this.$dialog.alert({
              title: '提示',
              className: 'purple-alert',
              message: '特惠专区为限时加购\n该时段已结束，敬请期待下次开放时间！',
              confirmButtonText: '返回首页',
              confirmButtonColor: '#FFF'
            }).then(() => {
              this.$router.push("/")
            }).catch(() => { });
          }
        }
      })
    },
    //根据商品id从购物车获取数量
    getCartNumById(goodId) {
      let storeListStr = window.localStorage.getItem("retailCartList") || '';
      let list = storeListStr ? JSON.parse(storeListStr) : [];
      let item = list.find(it => it.goodsId === goodId);
      if (item && item.cartCount > 0) this.value = item.cartCount;
      this.calcCartInfo(list);
    },
    numberChange() {
      let goodId = this.goodId;
      let storeListStr = window.localStorage.getItem("retailCartList") || '';
      let list = storeListStr ? JSON.parse(storeListStr) : [];
      let item = list.find(it => it.goodsId === goodId);
      if (item) {
        item.cartCount = this.value;
      } else {
        this.product.cartCount = this.value;
        list.push(this.product);
      }
      this.calcCartInfo(list);
      window.localStorage.setItem("retailCartList", JSON.stringify(list));
    },
    calcCartInfo(list) {
      let totalCount = 0, totalAmount = 0;
      list.forEach(it => {
        if (it.cartCount > 0) {
          totalCount += it.cartCount;
          totalAmount = Subtr(totalAmount, -accMul(it.cartCount, it.goodsPrice))
        }
      });
      this.totalCount = totalCount;
      this.totalAmount = totalAmount;
    },
    handleBack() {
      this.$router.back();
    }
  }
}
</script>
<style lang='scss' scoped>
.container {
  padding-bottom: 55px;
}
.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  display: flex;
  z-index: 9999;
  padding: 0 16px;
  align-items: center;
  border-top: 1px solid #f1f1f1;
  justify-content: space-between;
  background-color: #fff;

  .shop-cart {
    position: absolute;
    top: 7px;
    left: 16px;
    word-break: break-all;
    width: 30px;
    padding: 2px 6px;
    color: #fff;
    font-size: 12px;
    border-radius: 20%;
    text-align: center;
    background-color: #c4b3ff;
  }
  .total {
    text-align: left;
    padding-left: 49px;
    span {
      color: #c4b3ff;
    }
    .amount {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      color: #999999;
      font-size: 10px;
    }
  }
  .van-button {
    height: 33px;
    padding: 0 23px;
  }
}
.cus-stepper {
  position: absolute;
  right: 16px;
  bottom: 14px;
  z-index: 10;
  /deep/.van-stepper {
    .van-stepper__minus {
      color: #aaaaaa;
      border-color: #aaaaaa;
      &:after {
        height: 2px;
      }
    }
    .van-stepper__plus {
      background-color: #c4b3ff;
    }
  }
}
.iphoneX {
  .container {
    padding-bottom: 84px;
  }
  .footer {
    padding-bottom: 34px;
  }
}
</style>